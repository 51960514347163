<template>
  <div class="simple-market" data-t="simple-market">
    <OutcomeButton
      v-for="(outcome, idx) in outcomes"
      :key="outcome.uniqId || idx"
      :outcome="outcome"
      :market-status="market?.status ?? 0"
      v-bind="buttonProps"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  FilterConfig,
  OutcomeInFilters,
} from 'markets-store/marketsbook/types'
import { groupBy } from '@st/utils'
import type { TMarket, TOutcome } from 'markets-store/types'
import OutcomeButton from '../../OutcomeButton/OutcomeButton.vue'
import type { ButtonProps } from '../../../types'

interface Props {
  markets?: TMarket[]
  period?: number
  periodSpecifier?: string
  buttonProps?: ButtonProps
  marketsFilter?: FilterConfig
}

const props = withDefaults(defineProps<Props>(), {
  period: 0,
  periodSpecifier: '',
  markets: () => [],
  buttonProps: () => ({}),
  marketsFilter: undefined,
})

const defaultOutcome = Object.freeze({
  doubleOdds: ' – ',
  active: false,
  uniqId: 0,
}) as unknown as TOutcome

const market = computed(() => {
  if (!props.markets) return null

  return props.markets.find((m) => m.status)
})

const outcomes = computed(() => {
  if (!market.value) return [defaultOutcome, defaultOutcome, defaultOutcome]

  /* 
    если не переданы правила отрисовки ауткамов в маркете,
    выводим все ауткамы в порядке возрастания id
  */
  if (!props.marketsFilter?.outcomes) {
    return market.value.outcomes.slice().sort((a, b) => a.id - b.id)
  }

  const marketOutcomes = groupBy(market.value.outcomes, (outcome) => outcome.id)
  const marketId = market.value.id as keyof typeof props.marketsFilter.outcomes

  const outcomesRules: OutcomeInFilters[] = Array.isArray(
    props.marketsFilter.outcomes,
  )
    ? props.marketsFilter.outcomes
    : props.marketsFilter.outcomes[marketId]

  const outcomesByMarketId: OutcomeInFilters[] =
    props.marketsFilter.outcomesByMarketIds?.[marketId] ?? (outcomesRules || [])

  return outcomesByMarketId.map((outcomeId) => {
    if (!outcomeId || outcomeId === 'specifier') {
      return { computedState: 'hidden' } as unknown as TOutcome
    }

    return marketOutcomes.get(outcomeId)[0] || defaultOutcome
  })
})
</script>

<style scoped>
.simple-market {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;
}
</style>
