import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TMarket, TOutcome } from 'markets-store/types'
import { MARKET_ALLOWED_STATES } from 'markets-store/constants'
import { sortBy, groupBy } from '@st/utils'

interface Props {
  marketsFilter: Ref<FilterConfig | null>
  markets?: Ref<TMarket[]>
}

type SpecifierType = 'total' | 'hcp'

const defaultOutcome = Object.freeze({
  doubleOdds: ' – ',
  active: false,
}) as TOutcome
const defaultHomeAway = {
  home: defaultOutcome,
  away: defaultOutcome,
}

const MAX_GROUP_LENGTH = 5
const LEFT_GROUP_SIZE_FROM_CURRENT = 2
const RIGHT_GROUP_SIZE_FROM_CURRENT = 3

export function useSpecifierGroup(
  { marketsFilter, markets }: Props,
  type: SpecifierType = 'total',
) {
  const marketsGroup = computed(() => {
    if (markets?.value) {
      return Array.isArray(markets.value) ? markets.value : [markets.value]
    }
    return []
  })

  const specifiersSorted = computed(() =>
    sortBy(marketsGroup.value, (group) => [group.specifiers[type] ?? 0]),
  )

  const favoriteMarket = computed(() => {
    if (!marketsGroup.value?.length) return null
    return marketsGroup.value.reduce((prev, cur) =>
      prev.outcomesRange < cur.outcomesRange ? prev : cur,
    )
  })

  /*
    получение приоритетного специфаера, чтобы поместить его в центр пикера
    и сопоставление ближайших к нему значений (сверху/снизу)
  */
  const specifiersFiltered = computed(() => {
    function getCurrentPosition(
      specifiers: TMarket[],
      currentSpecifier: string,
    ) {
      const currentPos = specifiers.findIndex(
        (sp) => sp.specifiersString === currentSpecifier,
      )
      return currentPos < 0 ? 0 : currentPos
    }
    function sliceSpecifiers(specifiers: TMarket[], currentPos: number) {
      if (
        LEFT_GROUP_SIZE_FROM_CURRENT < currentPos &&
        specifiers[currentPos + LEFT_GROUP_SIZE_FROM_CURRENT]
      ) {
        return specifiers.slice(
          currentPos - LEFT_GROUP_SIZE_FROM_CURRENT,
          currentPos + RIGHT_GROUP_SIZE_FROM_CURRENT,
        )
      }

      if (currentPos <= LEFT_GROUP_SIZE_FROM_CURRENT) {
        return specifiers.slice(0, MAX_GROUP_LENGTH)
      }

      if (currentPos === specifiers.length - 1) {
        return specifiers.slice(
          specifiers.length - MAX_GROUP_LENGTH,
          specifiers.length + 1,
        )
      }

      return specifiers.slice(
        currentPos - RIGHT_GROUP_SIZE_FROM_CURRENT,
        currentPos + LEFT_GROUP_SIZE_FROM_CURRENT,
      )
    }

    const specifiersSortedLength = specifiersSorted.value.length
    if (specifiersSortedLength > MAX_GROUP_LENGTH) {
      const currentSpecifier = favoriteMarket.value?.specifiersString || ''
      const currentPos = getCurrentPosition(
        specifiersSorted.value,
        currentSpecifier,
      )
      return sliceSpecifiers(specifiersSorted.value, currentPos)
    }

    return specifiersSorted.value
  })

  const specifierValue = ref()
  const currentMarket = computed(() => {
    if (!marketsGroup.value) return null

    const userPickedOutcome = marketsGroup.value.find(
      (m) => m.specifiersString === specifierValue.value,
    )

    if (
      userPickedOutcome &&
      MARKET_ALLOWED_STATES.includes(userPickedOutcome?.status)
    )
      return userPickedOutcome

    return favoriteMarket.value
  })

  const outcomes = computed(() => currentMarket.value?.outcomes)

  const homeAway = computed(() => {
    if (!outcomes.value) return defaultHomeAway

    if (!Array.isArray(marketsFilter.value?.outcomes)) {
      const localHomeOutcomes = marketsFilter.value?.outcomes.home as number
      const localAwayOutcomes = marketsFilter.value?.outcomes.away as number

      const groupedOutcomes = groupBy(outcomes.value, (outcome) => outcome.id)
      return {
        home: (groupedOutcomes.get(localHomeOutcomes)[0] ||
          defaultOutcome) as TOutcome,
        away: (groupedOutcomes.get(localAwayOutcomes)[0] ||
          defaultOutcome) as TOutcome,
      }
    }

    return defaultHomeAway
  })

  watch(
    () => currentMarket.value,
    (value) => {
      if (!value && marketsGroup.value?.[0]) {
        const market = marketsGroup.value[0]
        // @ts-expect-error update method from market-store
        market.update({ favourite: 1, status: market.status, outcomes: [] })
      }
    },
    {
      immediate: true,
    },
  )

  watch(
    () => favoriteMarket.value,
    (market) => {
      const specifierString = market?.specifiers?.[type]
      if (specifierString || specifierString === 0) {
        specifierValue.value = `${type}=${specifierString}`
      }
    },
    {
      immediate: true,
    },
  )

  return {
    homeAway,
    currentMarket,
    specifierValue,
    specifiersFiltered,
  }
}
